<template>
  <div class="fullSync">
    <a-tabs v-model="active">
      <a-tab-pane key="1" tab="普通任务">
        <router-view v-if="active == '1'" executeType="NO_DELAY" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="定时任务">
        <a-tabs v-model="scheduledActive">
          <a-tab-pane key="1" tab="定时任务列表">
            <router-view
              v-if="scheduledActive == '1'"
              executeType="SCHEDULED"
            />
          </a-tab-pane>
          <a-tab-pane key="2" tab="子任务列表">
            <router-view
              v-if="scheduledActive == '2'"
              executeType="NO_DELAY"
              :parentTaskId="-1"
            />
          </a-tab-pane>
        </a-tabs>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  name: "fullSync",
  data() {
    return {
      active: "1",
      scheduledActive: "1",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/fullSync/noDelayTask") {
          this.active = "1";
        } else if (newVal == "/fullSync/scheduledTask") {
          this.active = "2";
          this.scheduledActive = "1";
        } else if (newVal == "/fullSync/scheduledSubTask") {
          this.active = "2";
          this.scheduledActive = "2";
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        if (newVal === "1") {
          if (this.$route.path == "/fullSync/noDelayTask") return;
          this.$router.replace("/fullSync/noDelayTask");
        }
        if (newVal === "2") {
          if (this.$route.path == "/fullSync/scheduledTask") return;
          this.$router.replace("/fullSync/scheduledTask");
        }
      },
    },
    scheduledActive: {
      immediate: true,
      handler(newVal) {
        if (this.active == "2") {
          if (newVal === "1") {
            if (this.$route.path == "/fullSync/scheduledTask") return;
            this.$router.replace("/fullSync/scheduledTask");
          }
          if (newVal === "2") {
            if (this.$route.path == "/fullSync/scheduledSubTask") return;
            this.$router.replace("/fullSync/scheduledSubTask");
          }
        }
      },
    },
  },
};
</script>
